








import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'status-column-header'
})
export default class StatusColumnHeader extends Vue {
  get headerContent() {
    return `${this.$tf('failed,,4 | records,,1')} / ${this.$tf('total | records,,1')}`;
  }
}
